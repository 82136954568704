export default {
    data() {
        return {
            categoryData: {
                '009': "#a3efff",
                '007': "#00d2ff",
                '006': "#5f45ff",
                '003': "#004be4",
                '002': "#02afff",
                '001': "#00a1e4",
                '0': "#04a1e4",
            },
            categoryList: [],
            mycharts1:{}
        }
    },
    methods: {
        getPieMap() {
            this.mycharts1 = this.$echarts.init(document.getElementById("pieMap"));
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: '0',
                    top:"5%",
                    textStyle: {
                        color: '#9fceff'
                    },
                },
                series: [{
                    name: '实时行业数据',
                    type: 'pie',
                    radius: '80%',
                    center: ['70%','55%'],
                    data: this.categoryList.map(item => {
                        return {
                            name: item.categoryName,
                            value: item.taskNum,
                            itemStyle: {
                                color: this.categoryData[item.id]
                            }
                        }
                    }),
                    label: {
                        show: false,
                    },
                    emphasis: {
                        show: false
                    }
                }]
            };
            this.mycharts1.setOption(option);
        }
    },
    mounted() {
        this.$api.get('/api/v1/getAllCategoryStatistics.json').then(res => {
            this.categoryList = res;
            this.getPieMap();
        })
    }
}