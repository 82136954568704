<template>
  <div class="home-circle">
    <div class="circle-main">
      <div class="out">
        <div class="dot a"></div>
        <div class="dot b"></div>
      </div>
      <div class="cen">
        <div class="text" v-html="html"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    html: {
      default: "",
      type: String,
    },
  },
};
</script>
<style lang="scss">
.home-circle {
  .circle-main {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    position: relative;
  }
  &.c2 {
    .out {
      background: url("../../assets/images/home/r2.png");
      background-size: cover;
      .dot {
        background-image: linear-gradient(60deg, #572cf3 0%, #21c0f2 100%),
          linear-gradient(#1e3994, #1e3994);
        &.b {
          background-image: linear-gradient(60deg, #21c0f2 0%, #572cf3 100%),
            linear-gradient(#1e3994, #1e3994);
        }
      }
    }
    .cen {
      &::before {
        background-image: linear-gradient(60deg, #572cf3 0%, #21c0f2 100%),
          linear-gradient(#1e3994, #1e3994);
      }
    }
  }
  &.c3 {
    .out {
      background: url("../../assets/images/home/r3.png");
      background-size: cover;
      .dot {
        background-image: linear-gradient(60deg, #f339e7 0%, #ede26a 100%),
          linear-gradient(#1e3994, #1e3994);
        background-blend-mode: normal, normal;
        &.b {
          background-image: linear-gradient(60deg, #ede26a 0%, #f339e7 100%),
            linear-gradient(#1e3994, #1e3994);
          background-blend-mode: normal, normal;
        }
      }
    }
    .cen {
      &::before {
        background-image: linear-gradient(60deg, #f32cf1 0%, #edf25e 100%),
          linear-gradient(#1e3994, #1e3994);
      }
    }
  }
  &.c4 {
    .out {
      background: url("../../assets/images/home/r4.png");
      background-size: cover;
      .dot {
        background-image: linear-gradient(60deg, #0a00b2 0%, #1fcc50 100%),
          linear-gradient(#1e3994, #1e3994);
        background-blend-mode: normal, normal;
        &.b {
          background-image: linear-gradient(60deg, #1fcc50 0%, #0a00b2 100%),
            linear-gradient(#1e3994, #1e3994);
          background-blend-mode: normal, normal;
        }
      }
    }
    .cen {
      &::before {
        background-image: linear-gradient(60deg, #0a00b2 0%, #1fcc50 100%),
          linear-gradient(#1e3994, #1e3994);
      }
    }
  }
  &.c5 {
    .out {
      background: url("../../assets/images/home/r5.png");
      background-size: cover;
      .dot {
        background-image: linear-gradient(60deg, #f04040 0%, #6426d7 100%),
          linear-gradient(#1e3994, #1e3994);
        background-blend-mode: normal, normal;
        &.b {
          background-image: linear-gradient(60deg, #6426d7 0%, #f04040 100%),
            linear-gradient(#1e3994, #1e3994);
          background-blend-mode: normal, normal;
        }
      }
    }
    .cen {
      &::before {
        background-image: linear-gradient(60deg, #f04040 0%, #6021d3 100%),
          linear-gradient(#1e3994, #1e3994);
      }
    }
  }
  &:hover {
    .circle-main {
      animation: 0.5s rainbow3 forwards linear;
      .out {
        animation: 2s rainbow forwards infinite linear;
      }
      .cen {
        transform: scale(0);
        animation: 0.5s rainbow3 forwards linear;
        animation-delay: 0.2s;
      }
    }
  }
  .out {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/home/r1.png");
    background-size: cover;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "";
      display: block;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border-radius: 50%;
    }
    .dot {
      width: 10%;
      height: 10%;
      position: absolute;
      border-radius: 50%;
      background-image: linear-gradient(60deg, #3101ff 0%, #f3a12c 100%),
        linear-gradient(#1e3994, #1e3994);
      background-blend-mode: normal, normal;
      &.a {
        top: 10%;
        left: 10%;
      }
      &.b {
        right: 10%;
        bottom: 10%;
        background-image: linear-gradient(60deg, #f3a12c 0%, #3101ff 100%),
          linear-gradient(#1e3994, #1e3994);
        background-blend-mode: normal, normal;
      }
    }
  }
  .cen {
    position: absolute;
    border-radius: 50%;
    width: 83%;
    height: 83%;
    top: 8.5%;
    left: 8.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      font-size: 18px;
      font-weight: bold;
      line-height: 30px;
      color: #ffffff;
      position: relative;
      z-index: 9;
      text-align: center;
      span {
        font-size: 15px;
      }
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      background-image: linear-gradient(60deg, #3101ff 0%, #f3a12c 100%),
        linear-gradient(#1e3994, #1e3994);
      background-blend-mode: normal, normal;
      opacity: 0.7;
    }
  }
}
@keyframes rainbow2 {
  from {
    transform: scale(0.1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes rainbow3 {
  from {
    transform: scale(0.1);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}
@keyframes rainbow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
