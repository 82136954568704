<template>
  <div class="home">
    <div class="banner">
      <!-- <img src="@/assets/images/home/banner.jpg" alt="" /> -->
      <div class="video">
        <video
          autoplay
          loop
          playsinline
          preload="auto"
          x5-playsinline
          webkit-playsinline
        >
          <source
            src="https://static.yizao2025.com/company.mp4"
            type="video/mp4"
          />
        </video>
        <div class="cover"></div>
      </div>
      <div class="banner-main">
        <div class="content">
          <div class="title anime">
            <h3>易造工业互联网</h3>
            <p>
              聚集全球制造类优质企业，共创、共享、共连，构建全产业链在线协同智造生态，为新的互联网时代的工业进步探索更有效的发展途径
            </p>
          </div>
          <div class="list anime-delay flex_b flex_align_c">
            <home-circle
              :class="[
                'anime-delay-list',
                'anime-delay-' + (index + 1),
                'c' + (index + 1),
              ]"
              v-for="(item, index) in bannerTextList"
              :key="item"
              :html="item"
            ></home-circle>
          </div>
        </div>
      </div>
    </div>
    <div class="big-data">
      <div class="content">
        <div class="home-title anime">
          <h3><span>Creation</span>易造</h3>
          <p class="sub">全球敏捷制造协同生态</p>
          <span class="el-icon-caret-bottom"></span>
          <p class="desc">
            链接制造业上下游产业链，打通连接层、平台层、应用层、提供端到端的一站式工业互联网平台
          </p>
        </div>
      </div>
      <div class="big-data-main flex_b">
        <div class="map">
          <div class="title">全产业链敏捷制造协同生态</div>
          <div id="chinaMap"></div>
          <div class="l-border"></div>
          <div class="r-border"></div>
        </div>
        <div class="data-list">
          <div class="data-t flex_b">
            <div class="statistics">
              <div class="sub-title">
                <div class="sub-title-main">
                  <img src="@/assets/images/home/icon_data.png" alt="" />
                  实时平台数据
                  <p><span></span><span></span><span></span></p>
                </div>
              </div>
              <div class="list">
                <div class="item" v-for="item in statisticsList" :key="item.id">
                  <div class="icon flex_c flex_align_c">
                    <img src="@/assets/images/home/icon_data.png" alt="" />
                  </div>
                  <div class="name">{{ item.title }}</div>
                  <div class="num">
                    <span>{{ statisticsData[item.code] }}个</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="category">
              <div class="category-map">
                <div class="sub-title">
                  <div class="sub-title-main">
                    <img src="@/assets/images/home/icon_data.png" alt="" />
                    实时行业数据
                    <p><span></span><span></span><span></span></p>
                  </div>
                </div>
                <div id="pieMap"></div>
              </div>
              <div class="btns">
                <div class="btn-item">
                  <span>我有制造需求</span>
                </div>
                <div class="btn-item">
                  <span>我是工厂</span>
                </div>
              </div>
            </div>
          </div>
          <div class="data-b">
            <div class="sub-title">
              <div class="sub-title-main">
                <img src="@/assets/images/home/icon_data.png" alt="" />
                实时需求信息
                <p><span></span><span></span><span></span></p>
              </div>
            </div>
            <div class="list">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in taskList"
                    :key="item.id"
                  >
                    <div class="item flex_b">
                      <div class="sort">{{ index + 1 }}</div>
                      <div class="date">{{ item.updateDate }}</div>
                      <div class="city">{{ item.city }}</div>
                      <div class="name">{{ item.taskUserName }}</div>
                      <div class="title">{{ item.title }}</div>
                      <div class="remarks">{{ item.remarks }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="solution">
      <div class="home-title anime">
        <h3><span>Creation</span>易造</h3>
        <p class="sub">制造业数字化解决方案</p>
        <span class="el-icon-caret-bottom"> </span>
        <p class="desc">
          易造秉承7C管理设计理念，集成了制造企业所需的各项功能，在一个系统内解决企业所有管理问题
        </p>
      </div>
      <div class="list anime-delay flex_b flex_align_c">
        <div
          v-for="(item, index) in solutionList"
          :key="item.id"
          :class="['item', 'anime-delay-list', 'anime-delay-' + (index + 1)]"
        >
          <el-image :src="item.back" fit="cover"></el-image>
          <div class="title">
            <h3>{{ item.title }}</h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="industrial">
      <div class="content">
        <div class="home-title anime">
          <h3><span>Creation</span>易造</h3>
          <p class="sub">工业产品及解决方案</p>
          <span class="el-icon-caret-bottom"></span>
        </div>
        <el-tabs v-model="activeName" class="anime">
          <el-tab-pane label="laas硬件解决方案" name="1"> </el-tab-pane>
          <el-tab-pane label="平台级产品" name="2"></el-tab-pane>
          <el-tab-pane label="应用级产品" name="3"></el-tab-pane>
        </el-tabs>
        <div class="list anime-delay flex_b" v-if="activeName == 1">
          <div
            :class="['item', 'anime-delay-list', 'anime-delay-' + (index + 1)]"
            v-for="(item, index) in industrialList"
            :key="item.id"
          >
            <el-image :src="item.icon" fit="cover"></el-image>
            <div class="info">
              <h3>{{ item.title }}</h3>
              <p>{{ item.desc }}</p>
              <div>
                <span v-for="items in item.label" :key="items">{{
                  items
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="list anime-delay flex_b" v-if="activeName == 2">
          <div
            :class="['item', 'anime-delay-list', 'anime-delay-' + (index + 1)]"
            v-for="(item, index) in industrialList"
            :key="item.id"
          >
            <el-image :src="item.icon" fit="cover"></el-image>
            <div class="info">
              <h3>{{ item.title }}2</h3>
              <p>{{ item.desc }}</p>
              <div>
                <span v-for="items in item.label" :key="items">{{
                  items
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="list anime-delay flex_b" v-if="activeName == 3">
          <div
            :class="['item', 'anime-delay-list', 'anime-delay-' + (index + 1)]"
            v-for="(item, index) in industrialList"
            :key="item.id"
          >
            <el-image :src="item.icon" fit="cover"></el-image>
            <div class="info">
              <h3>{{ item.title }}3</h3>
              <p>{{ item.desc }}</p>
              <div>
                <span v-for="items in item.label" :key="items">{{
                  items
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partner">
      <div class="home-title anime">
        <h3><span>Creation</span>易造</h3>
        <p class="sub">伙伴与厂商共赢机制</p>
        <span class="el-icon-caret-bottom"></span>
        <div class="desc">
          为企业数据提供多层次、端到端的安全防护端到端的安全防护端到端的安全防护
        </div>
      </div>
      <div class="partner-main">
        <div class="l"></div>
        <div class="content flex_b anime">
          <div class="info-l">
            <img src="@/assets/images/home/partner.png" alt="" />
          </div>
          <div class="info-r">
            <h3>实现伙伴与厂商的共赢机制</h3>
            <p>为企业数据提供多层次、端到端安全防护</p>
            <div>
              根云平台：支持私有化部署技术赋能业务合作伙伴，构建网络生态<br />
              生态伙伴：开通租户、设备接入、开发、销售、运营SaaS应用<br />
              设备生产商：使用设备和配套的SaaS应用
            </div>
            <span>了解我们的安全资源 >></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeCircle from "@/components/home-circle";
import chinaMap from "./mixins/chinaMap";
import pieMap from "./mixins/pieMap";
import Swiper from "@/assets/swiper/js/swiper.min.js";
import "@/assets/swiper/css/swiper.min.css";
export default {
  mixins: [chinaMap, pieMap],
  name: "Home",
  data() {
    return {
      industrialList: [
        {
          id: 1,
          icon: require("@/assets/images/home/icon1.png"),
          title: "工业物联网平台H-ITP",
          desc: "为物联网设备提供稳定的蜂窝联网服务",
          label: ["装备制造业", "汽车行业", "新能源行业"],
        },
        {
          id: 2,
          icon: require("@/assets/images/home/icon2.png"),
          title: "工业大数据平台H-BDP",
          desc: "基于云计算的智能化工业大数据平台",
          label: ["机械加工业", "汽车行业", "电子信息行业"],
        },
        {
          id: 3,
          icon: require("@/assets/images/home/icon3.png"),
          title: "工业知识图谱H-IKG",
          desc: "工业知识的管理和应用服务平台",
          label: ["装备制造业", "汽车行业", "电子信息行业"],
        },
        {
          id: 4,
          icon: require("@/assets/images/home/icon4.png"),
          title: "工业设备智能维保平台H-IEM",
          desc: "整合维保资源，提供标准化维保服务",
          label: ["机械加工业", "汽车行业", "装备制造业"],
        },
      ],
      activeName: "1",
      solutionList: [
        {
          id: 1,
          back: require("@/assets/images/home/merit1.jpg"),
          title: "能连接",
          desc: "低成本、普适应的链接，即插即用，99%+自动识别",
        },
        {
          id: 2,
          back: require("@/assets/images/home/merit2.jpg"),
          title: "强平台",
          desc: "做强工业属性的 灵活可配置中台，100%通过微服务支持上层应用需求",
        },
        {
          id: 3,
          back: require("@/assets/images/home/merit3.jpg"),
          title: "够安全",
          desc: "全方位保障客户安全，端到端全链路安全",
        },
        {
          id: 4,
          back: require("@/assets/images/home/merit4.jpg"),
          title: "多应用",
          desc:
            "赋能行业龙头，打造突现工业价值的应用，客户的SaaS投入产出效益10倍+",
        },
        {
          id: 5,
          back: require("@/assets/images/home/merit5.jpg"),
          title: "高智能",
          desc:
            "以AI为第一生产力，基于OT+IT数据，加工分析，建模学习，挖掘工业核心价值",
        },
      ],
      statisticsData: {
        platformActiveUsersNumber: "0", //平台活跃用户数
        platformEnterpriseServiceProvider: "0", //企业级服务商
        platformEnterprisesCloud: "0", //助力企业上云
        platformEnterprisesCloudDevice: "0", //上云设备
        platformOrderGMV: "0", //订单GMV
        platformRegisteredUsersNumber: "0", //平台注册用户数
      },
      statisticsList: [
        { id: 1, title: "平台活跃用户数", code: "platformActiveUsersNumber" },
        { id: 2, title: "订单GMV", code: "platformOrderGMV" },
        {
          id: 3,
          title: "平台注册用户数",
          code: "platformRegisteredUsersNumber",
        },
        { id: 4, title: "助力企业上云", code: "platformEnterprisesCloud" },
        {
          id: 5,
          title: "企业级服务商",
          code: "platformEnterpriseServiceProvider",
        },
        { id: 6, title: "上云设备", code: "platformEnterprisesCloudDevice" },
      ],
      bannerTextList: [
        "云端商务",
        "协同设计<br/><span>（CPDM）</span>",
        "协同生产<br/><span>（CRP、CMES）</span>",
        "企业云<span>(信息化)</span><br/><span>PLM/ERP/MES</span>",
        "生产<br/>大数据",
      ],
      taskList: [],
    };
  },
  components: {
    homeCircle,
  },
  methods: {
    getAllStatistics() {
      this.$sapi.get("/api/v1/getAllStatistics.json").then((res) => {
        this.statisticsData = res;
      });
    },
    setSwiper() {
      this.$sapi.get("/api/v1/getAllTaskInfo.json").then((res) => {
        this.taskList = res.list;
        this.$nextTick(() => {
          new Swiper(".swiper-container", {
            direction: "vertical", // 垂直切换选项
            slidesPerView: 6,
            autoplay: {
              delay: 5000,
              stopOnLastSlide: false,
              disableOnInteraction: true,
            },
          });
        });
      });
    },
    showAnimate(obj) {
      let _span = $(obj).find("p span");
      let timeObj = setTimeout(() => {
        _span.eq(0).show();
        clearTimeout(timeObj);
        timeObj = setTimeout((_) => {
          clearTimeout(timeObj);
          _span.eq(1).show();
          timeObj = setTimeout((_) => {
            clearTimeout(timeObj);
            _span.eq(2).show();
            timeObj = setTimeout((_) => {
              clearTimeout(timeObj);
              _span.hide();
              this.showAnimate(obj);
            }, 500);
          }, 500);
        }, 500);
      }, 500);
    },
  },
  created() {
    console.log("home-");
    this.getAllStatistics();
    this.setSwiper();
    /*监听窗口变化，重置echarts表格*/
    window.onresize = () => {
      this.mycharts1.resize();
      this.mycharts2.resize();
    };
    setTimeout(() => {
      this.$store.commit("anime");
    }, 300);
  },
  mounted() {
    $("video source").attr("src", "https://static.yizao2025.com/company.mp4");
    let _this = this;
    $('body').on('click',function(){
      $("video")[0].play()
    })
    $(".sub-title .sub-title-main").each(function () {
      _this.showAnimate(this);
    });
    this.$parent.finish();
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/home.scss";
</style>
